<template>
    <div>
        <div class="data">
            <div class="title">
                {{title.active}}
            </div>
            <el-table
                stripe
                border
                :data="page.data"
                style="width: 100%">
                <el-table-column
                    label="标题"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-home"></i>
                        <el-link @click="jump(scope.$index,scope.row)" type="primary">{{ scope.row.title }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建人"
                    width="180">
                    <template slot-scope="scope">
                        {{ scope.row.username && scope.row.username.nickname }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="180">
                    <template slot-scope="scope">
                        <format-time :time="scope.row.create_time" format="Y-m-d H:i:s"></format-time>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="90">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="removeAnswer(scope.$index,scope.row)" icon="el-icon-delete"
                                   type="danger"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page.sync="page.pageNumber"
                layout="total,prev, pager, next"
                :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import F from '@/fields/fields';
import FormatTime from "@/components/common/format-time.vue";

export default {
    components: {
        FormatTime
    },
    computed: {
        showFields: function () {
            return F.get('answer', 'verify').fields;
        },
    },
    data() {
        return {
            baseUrl: '/bbs/answer/verify',
            baseReplyUrl: '/bbs/reply/verify',
            baseCommentUrl: '/bbs/comment/verify',
            checkTimer:'',
            title:{
                active:'待审核数据',
                mapping:{answer:'待审核问答数据',reply:'待审核回复数据',comment:'待审核评论数据'},
            },
            page: {
                data: [],
                pageNumber: 1,
                total: 1,
            },
            search: {
                type: '',
            }
        }
    },
    mounted() {
        this.getData();
        window.xxx=this.checkDone;
    },
    watch: {
        '$route.params.type': function () {
            this.page.total = 0;
            this.page.pageNumber = 1;
            this.getData();
        }
    },
    methods: {
        pageChange: function () {
            this.getData();
        },
        getData: function () {
            this.search.type = this.$route.params.type || '';
            this.title.active=this.title.mapping[this.search.type]||'等审核数据';
            this.search.pageNumber = this.page.currentPage;
            let url = this.baseUrl;
            switch (this.search.type) {
                case 'reply':
                    url = this.baseReplyUrl;
                    break;
                case 'comment':
                    url = this.baseCommentUrl;
                    break;
            }
            this.$bbs.get(url, {
                params: this.search,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.data = data.data.data.map(v => {
                    switch (this.search.type) {
                        //如果是以下的两种类型，需要修改titie 方便展示
                        case 'reply':
                        case 'comment':
                            v.username = v.from_username;
                            v.title = this.$tools.stripHtmlTag(v.content);
                            if (v.title.length > 40) {
                                v.title = v.title.substr(0, 40) + '...';
                            }
                            break;
                    }
                    return v;
                });
                // this.page.currentPage = data.data.pageNumber;
            });
        },
        removeAnswer(index, data) {
            let title = data.title;
            if (title > 10) {
                title = title.substr(0, 10) + '...';
            }
            this.$confirm('你真的要删除:' + title + '?', '操作确认').then(() => {
                let url, saveData = {};
                switch (this.search.type) {
                    case 'answer':
                        url = this.baseUrl;
                        saveData = {answer_id: data.answer_id};
                        break;
                    case 'reply':
                        url = this.baseReplyUrl;
                        saveData = {reply_id: data.reply_id};
                        break;
                    case 'comment':
                        url = this.baseCommentUrl;
                        saveData = {comment_id: data.comment_id};
                        break;
                }
                this.$bbs.delete(url, {data:saveData,need:{error:true}}).then(() => {
                    this.page.data.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        },
        jump(index, data) {
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = '/wenda/detail/' + data.answer_id + '.html?verify=1&type=' + this.search.type + '&reply_id=' + (data.reply_id || '') + '&comment_id=' + (data.comment_id || '');
            a.click();
        },
        checkDone() {
            // debugger;
            let type = this.search.type;
            if (!type) return;
            let data = this.$store.state.notice[type];
            if (!data || !Array.isArray(data)) return;
            let key = '';
            switch (type) {
                case 'answer':
                    key = 'answer_id';
                    break;
                case 'comment':
                    key = 'comment_id';
                    break;
                case 'reply':
                    key = 'reply_id';
                    break;
            }
            if (!key) return;
            let del = [];
            data=JSON.parse(JSON.stringify(data)).map(v=>v[key]);
            this.page.data.map((v, index) => {
                if (data.indexOf(v[key]) > -1) del.push(index);
            });
            // console.log(del);
        },
    },
    beforeDestroy() {
        clearInterval(this.checkTimer);
    }
}

</script>
<style lang="less" scoped>
.data {
    margin-top: 1.5rem;
    .title {
        display: flex;
        background-color: #fff;
        font-weight: 600;
        padding: 10px;
        margin-bottom: 10px;
    }

    .jump-link {
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 5px 0;

        .index {
            text-align: center;
            width: 60px;
            flex-shrink: 0;
        }
    }
}

.page {
    background-color: #fff;
    padding:  10px 0;
    margin-top: 10px;
}
</style>
